/* eslint-disable @typescript-eslint/naming-convention */
import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Runner } from 'app/entity/ci-engine';
import { ApiService } from 'app/shared/services/api';
import {
  httpCiEngineSubscriptionActiveRunners,
} from 'app/shared/utils/endpoints/ci-engine.http';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionRunnerDataService extends GenericDataService<Runner> {
  protected requestOptions = {
    params: { 'no_filter': 0, 'page': 1, 'per_page': 10, 'status': 'active', 'order_by': 'status', 'order_dir': 'desc' },
    observe: 'response',
  };
  protected totalAmount = 0;

  constructor(
    apiService: ApiService,
  ) {
    super(httpCiEngineSubscriptionActiveRunners, apiService);
  }

  setOptions(page?: number, perPage?: number, status?: 'active'|'inactive', orderValue?: string, orderDir?: 'asc'|'desc'|''): void {
    this.requestOptions.params = {
      'no_filter': 0,
      'page': page || this.requestOptions.params.page,
      'per_page': perPage || this.requestOptions.params.per_page,
      'status': status || this.requestOptions.params.status,
      'order_by': orderDir !== undefined && orderDir !== '' && orderValue !== undefined ? orderValue : '',
      'order_dir': orderDir !== undefined ? orderDir : this.requestOptions.params.order_dir,
    };
  }

  getTotalAmount(): number {
    return this.totalAmount;
  }

  getAll(context: number[]): Observable<Runner[]> {
    return this.apiService.get(this.resolveUrl(context), this.requestOptions).pipe(
      map((response: HttpResponse<Runner[]>) => {
        this.totalAmount = parseInt(response.headers.get('X-Pagination-Total-Count'));
        return response.body as Runner[];
      }));
  }
}
