<div class="alert alert-danger" *ngIf="control && control.errors && (control.dirty || control.touched)">
  <span *ngIf="control.errors.required">
    {{ label }} is required.
  </span>

  <span *ngIf="control.errors.unique">{{ control.errors.unique }}</span>
  <span *ngIf="control.errors.lessThen">{{ control.errors.lessThen }}</span>
  <span *ngIf="control.errors.greaterThan">{{ control.errors.greaterThan }}</span>
  <span *ngIf="control.errors.confirmPassword">
    {{ control.errors.confirmPassword }}
  </span>

  <span *ngIf="control.errors.email">
    Email must be valid format.
  </span>

  <span *ngIf="control.errors.mobile">
    Mobile must be valid format.
  </span>

  <span *ngIf="control.errors.minlength">
    {{ label }} must be at least {{ control.errors.minlength.requiredLength }} characters long.
  </span>

  <span *ngIf="control.errors.maxlength">
    {{ label }} must be less than {{ control.errors.maxlength.requiredLength }} characters long.
  </span>

  <span *ngIf="control.errors.min">
    {{ label }} must be at least {{ control.errors.min.min }}.
  </span>

  <span *ngIf="control.errors.max">
    {{ label }} must be less than or equal to {{ control.errors.max.max }}.
  </span>

  <span *ngIf="control.errors.pattern">
    {{ pattern }}
  </span>

  <span *ngIf="control.errors.customPattern">
    {{ control.errors.customPattern }}
  </span>

  <span *ngIf="control.errors.passwordMatch">
    Passwords are not identical!
  </span>

  <span *ngIf="control.errors.noSecGroups">
    The Network Interface can't be detached. Every Network Interface needs at least one Security Group.
  </span>

  <span *ngIf="control.errors.trailingSpace">
    No trailing whitespace allowed.
  </span>

  <span *ngIf="control.errors.price">
    Enter a valid float with at most {{control.errors.price.decimals}} decimals.
  </span>

  <span *ngIf="control.errors.notValidIp">{{control.errors.notValidIp}}</span>
  <span *ngIf="control.errors.notInsideSubnet">{{control.errors.notInsideSubnet}}</span>
  <span *ngIf="control.errors.gatewayInPool">{{control.errors.gatewayInPool}}</span>

  <span *ngIf="control.errors.arrayLengthMin">
    Must have at least {{control.errors.arrayLengthMin.length}} values
  </span>
  <span *ngIf="control.errors.arrayLengthMax">
    Mustn't have more than {{control.errors.arrayLengthMax.length}} values
  </span>

  <span *ngIf="control.errors.endPortGreaterThanStartPort">
    End port must be greater than or equal to the start port
  </span>

  <span *ngIf="control.errors.noCidr">
    At least one cidr needs to be provided
  </span>
  <span *ngIf="control.errors.wronglyFormattedCidr">
    Remote CIDR is wrongly formatted
  </span>
  <span *ngIf="control.errors.overlappingCidr">
    Remote CIDR overlaps with local CIDR
  </span>
  <span *ngIf="control.errors.duplicatedCidr">
    CIDR cannot be duplicated
  </span>
  <span *ngIf="control.errors.overlappingCidrs">
    CIDRs are overlapping with another
  </span>
  <span *ngIf="control.errors.timoutIntervalRatio">
    Timout must be larger than the interval
  </span>
  <span *ngIf="control.errors.sameConcurrency">
    New concurrency cannot be the same as current concurrency
  </span>
</div>
