import { Injectable } from '@angular/core';
import { Order } from 'app/entity/admin';
import { ApiService } from 'app/shared/services/api';
import { httpAdminOrders } from 'app/shared/utils';
import { GenericDataService } from 'app/store/generic-store-infrastructure/generic.data';
import { Observable } from 'rxjs';
import { HttpResponse } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class OrderDataService extends GenericDataService<Order> {
  constructor(
    apiService: ApiService,
  ) {
    super(httpAdminOrders, apiService);
  }

  getPaginated(context: number[], pageNumber: number, itemCount: number): Observable<HttpResponse<Order[]>> {
    return this.apiService.get(this.resolveUrl(context), {
      params: {
        'page': pageNumber,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'per_page': itemCount,
      },
      observe: 'response',
    });
  }
}
