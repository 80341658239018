import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'myflow-side-panel',
  templateUrl: './side-panel.component.html',
  styleUrls: [ './side-panel.component.scss' ],
})
export class SidePanelComponent {
  @Input() title: string;
  @Input() loading$ = false;
  @Input() disabled$ = false;
  @Input() cancelAction = true;
  @Input() confirmAction = true;
  @Input() showFooter = true;
  @Input() action?: string;
  @Input() danger = false;

  @Output() closeEvent = new EventEmitter<void>();
  @Output() confirmEvent = new EventEmitter<void>();

  onClose(): void {
    this.closeEvent.emit();
  }

  onConfirm(): void {
    this.confirmEvent.emit();
  }
}
